import { trackEvent, TrackerData } from "@finn-no/pulse-sdk";

interface CreativeProps extends TrackerData {
	creativeId: string;
	elementId: string;
	verticalName: string;
	platform?: string;
	subVertical?: string;
}
interface CreativeLoadViewProps extends CreativeProps {}

export interface CreativeClickProps extends CreativeProps {
	targetId: string;
	provider: string;
}

function getObjectData({ creativeId, elementId }: { creativeId: string; elementId: string }): TrackerData["object"] {
	return {
		id: creativeId,
		type: "DisplayAd",
		adType: "Content",
		adFormat: "Single-view",
		adPosition: elementId,
		adCreative: creativeId,
	};
}

export function logCreativeLoad(props: CreativeLoadViewProps) {
	const { creativeId, elementId, verticalName, subVertical, name = "Display ad" } = props;

	trackEvent({
		type: "Load",
		name,
		object: getObjectData({
			creativeId,
			elementId,
		}),
		vertical: {
			name: verticalName,
			subVertical,
		},
	});
}

export function logCreativeView(props: CreativeLoadViewProps) {
	const { creativeId, elementId, verticalName, subVertical, name = "Display ad" } = props;

	trackEvent({
		type: "View",
		name,
		object: getObjectData({
			creativeId,
			elementId,
		}),
		vertical: {
			name: verticalName,
			subVertical,
		},
	});
}

export function logCreativeClick(props: CreativeClickProps) {
	const { creativeId, elementId, verticalName, subVertical, name = "Display ad", targetId, provider } = props;

	trackEvent({
		type: "Click",
		intent: "Open",
		name,
		object: getObjectData({
			creativeId,
			elementId,
		}),
		vertical: {
			name: verticalName,
			subVertical,
		},
		target: {
			id: targetId,
			referralCommission: false,
			type: "ExternalContent",
			provider: provider,
		},
	});
}
