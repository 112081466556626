import { PodletMetaData } from "../types/types-drEdition.js";

export function setupCustomElement(tagName: string, component: CustomElementConstructor) {
	if (!window.customElements.get(tagName)) {
		window.customElements.define(tagName, component);
	}
}

export function getPodletMetaData(): PodletMetaData {
	const podletElement = document?.querySelector("content-management-podlet-isolated");
	const dataElement =
		document.getElementById("podlet-meta-data") || podletElement?.shadowRoot?.getElementById("podlet-meta-data");

	if (!dataElement) {
		console.log("Data element not found");
		return {} as PodletMetaData;
	}

	return {
		landscapeMode: dataElement.dataset.landscapeMode === "true",
		isWebView: dataElement.dataset.isWebView === "true",
		darkMode: dataElement.dataset.darkMode === "true",
		vertical: dataElement.dataset.vertical,
		subvertical: dataElement.dataset.subVertical || "",
		pageType: dataElement.dataset.pageType,
		baseUrl: dataElement.dataset.baseUrl,
		locale: dataElement.dataset.locale || "",
	};
}
