// LitElement that is a paragraph block
import { css, html } from "lit";
import WarpElement from "@warp-ds/elements-core";
import { getPodletMetaData, setupCustomElement } from "../../lit-util.js";
import { PodletMetaData } from "../../../types/types-drEdition.js";
import { AssetFields } from "contentful/dist/types/types/asset.js";

const NAME = "contentful-sponsor-header";

export class ContentfulSponsorHeader extends WarpElement {
	declare entryId: string;
	declare sponsorName: string;
	declare logoImageData: AssetFields;
	declare metaData: PodletMetaData;

	static properties = {
		entryId: { type: String },
		sponsorName: { type: String },
		logoImageData: { type: Object },
	};

	constructor() {
		super();
		this.entryId = "";
		this.sponsorName = "";
		this.logoImageData = {} as AssetFields;
		this.metaData = getPodletMetaData();
	}

	static styles = [
		css`
			* {
				font-family: "Finntype", sans-serif;
			}

			:host {
				display: block;
			}

			.sponsorLabel {
				font-size: var(--w-font-size-m);
			}

			@media (max-width: 468px) {
				.logo-wrapper {
					max-width: 300px;
				}
				.sponsorLabel {
					font-size: var(--w-font-size-s);
				}
			}

			#sponsor-header.dark-mode {
				color: white;
			}

			#sponsor-header.dark-mode * {
				color: white;
			}

			#sponsor-header.dark-mode img {
				background: white;
			}
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.static{position:static}.h-48{height:4.8rem};
		`,
		...WarpElement.styles,
	] as unknown as never;

	getSponsorImage() {
		return html`<div
			class="logo-wrapper ${this.metaData.darkMode ? "s-bg" : ""} ${this.metaData.landscapeMode ? "s-bg" : ""}"
		>
			<contentful-image
				entryId="${this.entryId}"
				fieldId="logoImageData"
				imageStyle="max-height: 40px; max-width: 200px; object-fit: cover;"
				height="40px"
				width="auto"
				.imageData="${this.logoImageData}"
			></contentful-image>
		</div>`;
	}

	render() {
		return html`<div
			id="sponsor-header"
			class="relative flex items-center justify-between p-8 
				${this.metaData.isWebView ? "h-36" : "h-48"} 
				${this.metaData.darkMode ? "dark-mode" : ""}"
		>
			<span class="sponsorLabel block ml-3 text-regular font-bold"
				>${this.i18n.t({
					id: "common.sponsorLabel",
					message: "Annonsørinnhold",
				})}</span
			>
			${this.getSponsorImage()}
		</div>`;
	}
}

setupCustomElement(NAME, ContentfulSponsorHeader);
