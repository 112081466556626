import { css, html } from "lit";
import { getPodletMetaData, setupCustomElement } from "../../lit-util.js";
import WarpElement from "@warp-ds/elements-core";
import "@warp-ds/elements/components/button";
import { PodletMetaData } from "../../../types/types-drEdition.js";
import { logCreativeClick, logCreativeLoad, logCreativeView } from "../../tracking/sponsored-content-tracking.js";
import { CmpContent, CmpImage } from "src/types/types-cmp.js";

const NAME = "sponsored-content-cmp-wrapper";

export class SponsoredContentCmpWrapper extends WarpElement {
	declare metaData: PodletMetaData;

	declare cmpContent: CmpContent;
	declare hasTracking: boolean;

	constructor() {
		super();
		this.hasTracking = false;
		this.metaData = getPodletMetaData();
		this.cmpContent = {} as CmpContent;
	}

	static properties = {
		hasTracking: { type: Boolean },
		cmpContent: { type: Object },
	};

	static styles = [
		css`
			* {
				font-family: "Finntype", sans-serif;
			}

			:host > article {
				padding: 0;
				color: #333;
				min-height: 200px;
				overflow: hidden;
				transition:
					background-color 0.3s ease,
					color 0.3s ease;
			}

			button {
				transition: border-color 0.3s ease;
			}

			:host article:hover button {
				border-color: black;
			}

			:host > article.dark-mode {
				background-color: rgb(43, 43, 48);
				color: white;
			}

			:host > article.dark-mode * {
				color: white;
			}

			:host article.dark-mode #cta-button {
				background-color: rgb(26, 26, 30);
				color: white;
				border-color: white;
			}

			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.border-solid{border-style:solid}.opacity-0{opacity:0}.static{position:static}.hover\:s-bg-hover:hover{background-color:var(--w-s-color-background-hover)}.hover\:s-border-primary-hover:hover{border-color:var(--w-s-color-border-primary-hover)}.my-8{margin-top:.8rem;margin-bottom:.8rem}.mt-8{margin-top:.8rem}.py-16{padding-top:1.6rem;padding-bottom:1.6rem}.transition{transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter,backdrop-filter;transition-duration:.15s;transition-timing-function:cubic-bezier(.4,0,.2,1)}.text-16{font-size:var(--w-font-size-m);line-height:var(--w-line-height-m)}.text-l{font-size:var(--w-font-size-l);line-height:var(--w-line-height-l)}.text-ml{font-size:var(--w-font-size-ml);line-height:var(--w-line-height-ml)};
		`,
		...WarpElement.styles, // Adds the global styles
	] as unknown as never;

	firstUpdated() {
		if (this.hasTracking) {
			this.initialTracking();
		}
	}

	initialTracking() {
		this.trackLoad();
		this.setupTrackView();
	}

	setupTrackView() {
		const target = this.shadowRoot?.getElementById(this.cmpContent.id);
		if (target) {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							this.trackView();
							observer.disconnect();
						}
					});
				},
				{
					threshold: 0.5,
					rootMargin: "50px 0px 50px 0px",
				},
			);
			observer.observe(target);
		}
	}
	trackLoad() {
		logCreativeLoad({
			creativeId: this.cmpContent.id,
			elementId: this.cmpContent.id,
			verticalName: this.metaData?.vertical || "",
			platform: "web",
			provider: this.cmpContent.advertiserName,
		});
	}

	trackClick() {
		logCreativeClick({
			creativeId: this.cmpContent.id,
			elementId: this.cmpContent.id,
			verticalName: this.metaData?.vertical || "",
			platform: "web",
			targetId: this.cmpContent.url || "",
			provider: this.cmpContent.advertiserName,
		});
	}

	trackView() {
		logCreativeView({
			creativeId: this.cmpContent.id,
			elementId: this.cmpContent.id,
			verticalName: this.metaData?.vertical || "",
			platform: "web",
			provider: this.cmpContent.advertiserName,
		});
	}

	isMobile() {
		const wrapperWidth = this.getBoundingClientRect().width;
		return wrapperWidth < 768;
	}

	convertCmpToImageField(cmpImage: CmpImage, alt: string) {
		return {
			title: alt,
			file: {
				url: cmpImage.url,
				image: {
					width: cmpImage.width,
					height: cmpImage.height,
				},
			},
		};
	}

	render() {
		const { metaData } = this;
		const content = this.cmpContent;
		// Check if content is not empty object
		if (!Object.keys(content).length) return html``;
		const { id, url, image, advertiserIconImage, kicker, title, text, tagline, ctaButtonText, advertiserName } =
			content;
		return html`
			<contentful-sponsor-header
				sponsorName="${advertiserName}"
				.logoImageData="${this.convertCmpToImageField(advertiserIconImage, advertiserName)}"
			></contentful-sponsor-header>
			<article
				class="shadow-m relative rounded-8 rounded-8 ${metaData.darkMode ? "dark-mode" : "s-bg hover:s-bg-hover"} "
				id="${id}"
			>
				<contentful-image
					.imageData="${this.convertCmpToImageField(image, title)}"
					.aspectRatio="${true}"
					landscapeMode="${metaData.landscapeMode}"
					imageStyle="width: 100%"
				></contentful-image>
				<div class="${metaData.isWebView ? "px-8 py-16" : "p-16"} card">
					${kicker
						? html`<contentful-paragraph class="font-bold text-ml mb-0 mt-8">${kicker}</contentful-paragraph>`
						: ""}
					<contentful-heading heading="heading-2" class="font-normal text-l">${title}</contentful-heading>
					${tagline ? html`<contentful-paragraph fadeout="true">${text}</contentful-paragraph>` : ""}
					${ctaButtonText
						? html`<button
								id="cta-button"
								class="my-8 inline-block px-16 py-8 rounded-8 border border-solid text-16 ${metaData.darkMode
									? ""
									: "s-bg s-border hover:s-border-primary-hover"} "
							>
								${ctaButtonText}
							</button>`
						: ""}
				</div>
				<slot></slot>
				${url
					? html`<a
							target="_blank"
							rel="noreferrer"
							@click="${this.trackClick}"
							class="inset-0 absolute opacity-0"
							href="${url}"
							>${kicker} ${title}</a
						>`
					: ""}
			</article>
		`;
	}
}

setupCustomElement(NAME, SponsoredContentCmpWrapper);
