import { trackEvent, TrackerData } from "@finn-no/pulse-sdk";

function getVideoObject(
	creativeId: string,
	durationSeen: number,
	durationInVideo: number,
	intent: string,
	vertical: string,
): TrackerData["object"] {
	return {
		assetType: "Video",
		autoplay: true,
		intent,
		pcFormat: "adfree",
		tags: ["content-marketing", "video-ad", `${vertical}-ad`],
		duration: Math.ceil(durationInVideo) || 0,
		position: Math.ceil(durationSeen) || 0,
		embed: false,
		fullscreen: false,
		muted: true,
		streamType: "vod",
		name: "contentMarketingVideo",
		category: "",
		adFormat: "videocontent",
		// @ts-expect-error - This is a known issue with the pulse-sdk
		type: "MediaAsset",
		id: creativeId,
	};
}

export function logVideoLoad(
	creativeId: string,
	verticalName: string,
	subVertical: string,
	durationSeen: number,
	durationInVideo: number,
): void {
	const intent = "Load";
	trackEvent({
		type: "Engagement",
		intent,
		name: "Video Ad",
		object: getVideoObject(creativeId, durationSeen, durationInVideo, intent, verticalName),
		vertical: {
			name: verticalName,
			subVertical,
		},
	});
}

export function logVideoPlay(
	creativeId: string,
	verticalName: string,
	subVertical: string,
	durationSeen: number,
	durationInVideo: number,
): void {
	const intent = "Play";
	trackEvent({
		type: "Engagement",
		intent,
		name: "Video Ad",
		object: getVideoObject(creativeId, durationSeen, durationInVideo, intent, verticalName),
		vertical: {
			name: verticalName,
			subVertical,
		},
	});
}

export function logVideoPause(
	creativeId: string,
	verticalName: string,
	subVertical: string,
	durationSeen: number,
	durationInVideo: number,
): void {
	const intent = "Pause";
	trackEvent({
		type: "Engagement",
		intent,
		name: "Video Ad",
		object: getVideoObject(creativeId, durationSeen, durationInVideo, intent, verticalName),
		vertical: {
			name: verticalName,
			subVertical,
		},
	});
}
