import { html, css } from "lit";
import { setupCustomElement } from "../../../lit-util.js";
import WarpElement from "@warp-ds/elements-core";
import { TypesDrEdition, ComponentMetaData } from "../../../../types/types-drEdition.js";
import { getPercentage } from "src/client/helper.js";
import { logCreativeClick, logCreativeLoad, logCreativeView } from "../../../tracking/sponsored-content-tracking.js";
import "@warp-ds/elements/components/button";
import { events, messageBus } from "@schibsted-nmp/advertising-events";

const NAME = "advt-sponsored-content-teaser";

class AdvtSponsoredContent extends WarpElement {
	contentMarketing!: TypesDrEdition[];
	metaData!: ComponentMetaData;
	private importedHlsVideo: boolean = false; // flag to track hls-video import status

	static properties = {
		contentMarketing: { type: Array },
		metaData: { type: Object },
	};

	static styles = [
		css`
			* {
				font-family: "Finntype", sans-serif;
			}
			.advt-sponsored-content__multiple-container {
				display: flex;
				overflow-x: auto;
				scrollbar-width: none; /* For Firefox */
				-ms-overflow-style: none; /* For Internet Explorer and Edge */
			}

			.advt-sponsored-content__multiple-container::-webkit-scrollbar {
				display: none; /* For Chrome, Safari and Opera */
			}
			.advt-sponsored-content__multiple-container > article {
				flex: 0 0 auto;
				scroll-snap-align: start;
				max-width: 70%;
				padding: 6px;
				display: flex;
				flex-direction: column;
				height: 100%;
			}

			.text-fadeout {
				mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
				overflow-y: hidden;
			}
			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.aspect-1\/5{padding-bottom:500%;position:relative}.aspect-1\/5>*{width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0}.aspect-16\/9{padding-bottom:56.25%;position:relative}.aspect-16\/9>*{width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0}.aspect-3\/2{padding-bottom:66.6667%;position:relative}.aspect-3\/2>*{width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0}.rounded-t-8{border-top-left-radius:8px;border-top-right-radius:8px}.col-span-full{grid-column:1/-1}.static{position:static}.h-14{height:1.4rem}.h-28{height:2.8rem}.m-2{margin:.2rem}.my-4{margin-top:.4rem;margin-bottom:.4rem}.mt-1{margin-top:.1rem}.pb-12{padding-bottom:1.2rem}.object-cover{object-fit:cover}.text-xl{font-size:var(--w-font-size-xl);line-height:var(--w-line-height-xl)}@media (min-width:480px){.sm\:p-6{padding:.6rem}}@media (min-width:768px){.md\:h-16{height:1.6rem}.md\:h-24{height:2.4rem}};
		`,
		...WarpElement.styles, // Adds the global styles
	] as unknown as never;

	connectedCallback() {
		super.connectedCallback();
		this.setPodletMetaData();
		this.initializeContentMarketing();
	}

	async initializeContentMarketing() {
		if (!this.contentMarketing) {
			const contentMarketingRes = await this.fetchContentMarketingData();
			if (contentMarketingRes?.length) {
				this.initialTracking(contentMarketingRes);
				this.requestUpdate();
			} else {
				messageBus.publish(events.PODLET.channel, events.PODLET.CONTENT_MARKETING_NO_SOV.topic);
			}
		} else {
			this.initialTracking(this.contentMarketing);
		}
	}

	trackLoad(content: TypesDrEdition) {
		const { id } = content;
		logCreativeLoad({
			creativeId: id,
			elementId: id,
			verticalName: this.metaData?.vertical || "",
			elementType: "",
			platform: "web",
		});
	}

	trackClick(content: TypesDrEdition) {
		const { id, variantName, sponsor, href } = content;
		logCreativeClick({
			creativeId: id,
			variantName,
			elementId: id,
			verticalName: this.metaData?.vertical || "",
			elementType: "",
			platform: "web",
			targetId: href,
			provider: sponsor,
		});
	}

	trackView(content: TypesDrEdition) {
		logCreativeView({
			creativeId: content.id,
			elementId: content.id,
			verticalName: this.metaData?.vertical || "",
			elementType: "",
			platform: "web",
		});
	}

	setupTrackView(content: TypesDrEdition) {
		const { id } = content;
		const target = this.shadowRoot?.getElementById(id);
		if (target) {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							this.trackView(content);
							observer.disconnect();
						}
					});
				},
				{
					threshold: 0.5,
					rootMargin: "50px 0px 50px 0px",
				},
			);
			observer.observe(target);
		}
	}

	setPodletMetaData() {
		const podletElement = document?.querySelector("content-management-podlet-isolated");
		const dataElement = podletElement?.shadowRoot?.getElementById("podlet-meta-data");

		if (!dataElement) {
			console.log("Data element not found");
			return;
		}

		const { vertical, subvertical, pageType, baseUrl } = dataElement.dataset;

		this.metaData = {
			...this.metaData,
			vertical: this.metaData?.vertical || vertical,
			subvertical: this.metaData?.subvertical || subvertical,
			pageType: this.metaData?.pageType || pageType,
			baseUrl: this.metaData?.baseUrl || baseUrl,
		};
	}

	async fetchContentMarketingData(): Promise<TypesDrEdition[]> {
		if (!this.metaData) return [];
		const { baseUrl, vertical, subvertical, pageType } = this.metaData;
		const fetchUrl = `${baseUrl}/api/advertising/data/verticals/${vertical}/subvertical/${subvertical}/pageType/${pageType}`;
		try {
			const res = await fetch(fetchUrl);
			const contentMarketingRes = await res.json();
			if (contentMarketingRes?.length) {
				this.contentMarketing = contentMarketingRes;
			}
			return contentMarketingRes || [];
		} catch (e) {
			console.log(e);
			return [];
		}
	}

	initialTracking(contentMarketing: TypesDrEdition[]) {
		contentMarketing?.forEach((content) => {
			this.trackLoad(content);
			this.setupTrackView(content);
		});
	}

	updated(changedProperties: Map<string | number | symbol, unknown>) {
		async function importHls() {
			await import("./hls-video.js");
		}

		if (!this.importedHlsVideo && changedProperties.has("contentMarketing") && this.contentMarketing) {
			const hasVideoContent = this.contentMarketing.some((content) => content.videoUrl);
			if (hasVideoContent) {
				importHls().then(() => {
					this.importedHlsVideo = true;
				});
			}
		}
	}

	getSponsorHeader(content: TypesDrEdition) {
		return html`
			<div class="flex items-center justify-between px-8">
				<span class="ml-3 text-sm font-bold text-gray-900"
					>${this.i18n.t({
						id: "common.sponsorLabel",
						message: "Annonsørinnhold",
					})}</span
				>
				${this.getSponsorImage(content)}
			</div>
		`;
	}

	getSponsorImage(content: TypesDrEdition) {
		const { icon, sponsor } = content;
		function calculateParentHeight(width: string, height: string): string {
			const ratio = Number(width) / Number(height);
			const LARGE_ICON = "h-28";
			const MID_ICON = "h-20 md:h-24";
			const SMALL_ICON = "h-14 md:h-16";

			if (ratio > 3.0) return SMALL_ICON;
			else if (ratio > 1.2) return MID_ICON;
			else return LARGE_ICON;
		}
		const iconSizeClass = calculateParentHeight(icon.width, icon.height);

		return html` <img alt="${sponsor} logo" class="${iconSizeClass}" src="${icon.url}" /> `;
	}
	getTextualContent(content: TypesDrEdition) {
		const { text, textDesktop, title, introduction, ctaText } = content;

		const isMobile = window.innerWidth < 768;
		const tagLine = isMobile ? text : textDesktop ? textDesktop : text;

		return html`
			<div class="my-4 p-8 pb-12">
				${text ? html`<p class="text-sm text-gray-700 m-0">${tagLine}</p>` : ""}
				${title ? html`<h3 class="text-xl leading-6 font-medium text-gray-900">${title}</h3>` : ""}
				${introduction ? html`<p class="mt-1 text-sm leading-5 text-gray-500 text-fadeout">${introduction}</p>` : ""}
				${ctaText ? html`<w-button variant="secondary">${ctaText}</w-button>` : ""}
			</div>
		`;
	}

	getImageOrVideo(content: TypesDrEdition) {
		const { videoUrl, id } = content;
		if (videoUrl) {
			return html`<hls-video src="${videoUrl}" id="${id}"></hls-video>`;
		} else {
			return this.getImageWithZoom(content);
		}
	}

	getImageWithZoom(content: TypesDrEdition) {
		const { image, title } = content;

		function getObjectPosition() {
			if (image?.aoi?.focus) {
				const y = getPercentage(image.aoi.focus.y, image.height);
				const x = getPercentage(image.aoi.focus.x, image.width);
				return `${x}% ${y}%`;
			} else {
				return "50% 50%";
			}
		}

		function getAspectRatio() {
			const isMobile = window.innerWidth < 768;
			const landscapeMode = window.innerWidth > window.innerHeight;
			return landscapeMode ? "aspect-1/5" : isMobile ? "aspect-3/2" : "aspect-16/9";
		}

		function getRadius() {
			return "rounded-t-8";
		}

		return html`
			<div class="overflow-hidden relative h-full w-full">
				<div class="${getAspectRatio()}">
					<img
						loading="lazy"
						alt="${title}"
						src="${image?.url}"
						class="h-full w-full object-cover ${getRadius()}"
						style="object-position: ${getObjectPosition()}"
					/>
				</div>
			</div>
		`;
	}

	renderAnchor(content: TypesDrEdition) {
		const { href, title } = content;

		return html`<a
			href="${href || "#"}"
			target="_blank"
			rel="noreferrer"
			class="absolute inset-0 z-10"
			aria-label="Read more about ${title || "this sponsored content"}"
			@click="${() => {
				this.trackClick(content);
			}}"
		></a>`;
	}

	getRealEstatePart(content: TypesDrEdition, renderSponsor: boolean) {
		const { id } = content;
		return html`
			<article id="${id || ""}" class="col-span-full py-8 m-2 relative block flex flex-col h-full">
				${this.renderAnchor(content)} ${renderSponsor ? this.getSponsorHeader(content) : ""}
				<div class="flex flex-col h-full flex-grow">
					<div class="py-5 sm:p-6 rounded-8 shadow-m bg-white flex-grow">
						${this.getImageOrVideo(content)} ${this.getTextualContent(content)}
					</div>
				</div>
			</article>
		`;
	}

	getCarPart(content: TypesDrEdition, renderSponsor: boolean) {
		const { id } = content;
		return html`
			<article
				id="${id || ""}"
				class="col-span-full py-8 m-2 relative block bg-white shadow-lg rounded-lg flex flex-col h-full"
			>
				${this.renderAnchor(content)} ${renderSponsor ? this.getSponsorHeader(content) : ""}
				${this.contentMarketing.length === 1 ? this.getSponsorHeader(content) : ""}
				<div class="flex flex-col h-full">
					<div class="px-4 py-5 sm:p-6">${this.getImageOrVideo(content)} ${this.getTextualContent(content)}</div>
				</div>
			</article>
		`;
	}

	renderContentPart(content: TypesDrEdition, renderSponsor: boolean) {
		if (this.metaData.pageType === "result" && this.metaData.vertical === "realestate") {
			return this.getRealEstatePart(content, renderSponsor);
		} else if (this.metaData.pageType === "result" && this.metaData.vertical === "car") {
			return this.getCarPart(content, renderSponsor);
		} else {
			return "";
		}
	}

	render() {
		if (Array.isArray(this.contentMarketing)) {
			if (this.contentMarketing.length === 0) return html``;
			if (this.contentMarketing.length > 1) {
				return html`
					<div>
						${this.getSponsorHeader(this.contentMarketing[0])}

						<div class="advt-sponsored-content__multiple-container col-span-full">
							${this.contentMarketing.map((content) => this.renderContentPart(content, false))}
						</div>
					</div>
				`;
			}
			return this.renderContentPart(this.contentMarketing[0], true);
		} else if (this.contentMarketing) {
			return this.renderContentPart(this.contentMarketing, true);
		} else {
			return html``;
		}
	}
}

setupCustomElement(NAME, AdvtSponsoredContent);
